import React from 'react'
import './portfolio.css'
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h2 className='portfolioTitle'>Portfolio</h2>
      <p className='portfolioDescription'>As a web developer, I am dedicated to helping businesses establish a powerful online presence. Through innovative design and cutting-edge technology, I create websites that not only showcase their brand but also drive engagement and success in the digital landscape.With a passion for clean code and intuitive design, I'm always pushing the boundaries of what's possible. My portfolio features vast variety of projects, all built with proven best practices and SEO optimization.
      </p>
      <div style={{display:'flex',justifyContent:'center',padding:'0 6rem',margin:'0 4rem 2rem 4rem'}}>
        <div style={{textAlign:'center'}}>
        <a href="https://www.makeupbyellie.com/"><img src="portfolio-makeupbyellie-v1.jpg" alt="" />
        <h2>Make Up by Ellie</h2>
        </a>
        </div>
        <div style={{textAlign:'center'}}>
        <a href="https://blendpr.com.au/">
        <img src="portfolio-blend-v1.jpg" alt=""/>
        <h2>Blend PR</h2>
        </a>
        </div>
        <div style={{textAlign:'center'}}>
        <a href="https://www.grassports.com.au/">
        <img src="portfolio-grassports.jpg" alt=""/>
        <h2>Grassports AU</h2>
        </a>
        </div>
        <div style={{textAlign:'center'}}>
        <a href="https://www.aailaw.com.au/">
        <img src="AA-International-portfolio.jpg" alt="" />
        <h2>AAI Law</h2>
        </a>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
