import React from 'react'
import './navbar.css'
import {Link} from 'react-scroll'

const Navbar = () => {
  return (
    <nav className="navbar">
        <img src="logo-pankaj-v2.png" alt="" className="logo" />
        <div className="desktopMenu">
            <Link className='desktopMenuListItem' >Home</Link>
            <Link className='desktopMenuListItem' activeClass="active" smooth spy to="skills" offset={-60}>About</Link>
            <Link className='desktopMenuListItem' activeClass="active" smooth spy to="portfolio" offset={-60}>Portfolio</Link>
            <Link className='desktopMenuListItem' activeClass="active" smooth spy to="client" offset={-60}>Clients</Link>
        </div>
        <button className='desktopMenuBtn'>
            Contact Me
        </button>
    </nav>
  )
}

export default Navbar
