import React from 'react'
import './client.css'
const Client = () => {
  return (
    <section id='client'>
      <h2 className='clientTitle'>Clients</h2>
      <p className='clientDescription'>
      I've had the pleasure of collaborating with a diverse range of clients, from creative startups to established businesses in various industries. Each project brought unique challenges and opportunities, allowing me to hone my skills in web development and deliver tailored solutions that exceeded expectations.
      </p>
      <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
        
        <img src="logo-blackwoodfamily.gif" alt="" />  
        
        <img src="logo-cgl-v1.jpg" alt="" />  
        
        <img src="logo-luxe-v1.jpg" alt="" />  

        <img src="logo-dsa-v1.jpg" alt="" />  
        
        
      </div>

      <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap'}}>
        
        <img src="logo-onratio-v1.jpg" alt="" />  
        
        <img src="logo-pef-v1.jpg" alt="" />  
        
        <img src="logo-psfc-v1.jpg" alt="" />  

        <img src="logo-udg-v1.jpg" alt="" />  
        
        
      </div>
    </section>
  )
}

export default Client
