import {Navbar,Intro,Skills, Portfolio,Client} from './components'

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Intro/>
      <Skills/>
      <Portfolio/>
      <Client/>
    </div>
  );
}

export default App;
