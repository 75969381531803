import React from 'react'
import './intro.css';
import { Link } from 'react-scroll';


const Intro = () => {
  return (
    <section id='intro'>
        <div className="introContent">
            <span className='hello'>Hello,</span>
            <span className="introText">
                I'm <span className="introName">Pankaj</span><br/>Web Developer</span>
            <p className="introPara">
                I am a skilled web developer with experience in creating<br/>visually appealing and user friendly websites.
            </p>
            <Link>
            <button className='btn'>
              <img src="message-50.png" alt="" className='btnImage' />
            Get In Touch
        </button>
            
            </Link>
            
        </div>
        <img src="profile-pankaj-v2.png" alt="" className='bg' />
    </section>
  )
}

export default Intro
