import React from 'react';
import './skills.css';

const Skills = () => {
  return (
    <section id='skills'>
      <h2 className='whatIDo'>What I Do</h2>
      <p className='skillsDescription'>I am a skilled and passionate web developer, with experience in creating visually appealing and user-friendly websites. I have a strong understanding of design and a keen eye for details. I am proficient in HTML, CSS, PHP, Javascript, jQuery, REACT, Express, Node.js, mySQL, as well as design software such as Adobe Photoshop</p>
      <div className="skillBars">
        <img src="ui-design.png" alt="" className="skillBarImg" />
        <div className="skillBarText">
        <h2>UI/UX Design</h2>
        <p>Experience in crafting visually stunning and intuitive user interfaces using Adobe Photoshop. Proficient in leveraging the full spectrum of Photoshop tools to create pixel-perfect designs, from wireframes to high-fidelity mockups.</p>
        </div>
      </div>
      <div className="skillBars">
      <img src="web-design.png" alt="" className="skillBarImg" />
        <div className="skillBarText">
        <h2>Website Development</h2>
        <p>Keen eye for aesthetics, skilled in creating visually appealing and user-friendly websites. Leveraging the power of React, I design and implement highly responsive and interactive user interfaces</p>
        </div>
      </div>
      <div className="skillBars">
      <img src="api-development.png" alt="" className="skillBarImg" />
        <div className="skillBarText">
        <h2>API Development</h2>
        <p>Specialized in creating robust and scalable web APIs using the dynamic duo of Node.js and Express. My proficiency extends from designing and architecting APIs to implementing efficient and secure endpoints</p>
        </div>
      </div>
    </section>
  )
}

export default Skills
